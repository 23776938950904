import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, FunctionComponent, ReactNode } from 'react';

import Chevrelon from '../../icons/cheverlon';

const Select: FunctionComponent<{
  menuPlacement?: 'top' | 'bottom';
  disabled?: boolean;
  element?: ReactNode;
  onChange: (value: any) => void;
  label: ReactNode;
  value: any;
  options?: Array<{ id: string; value: any; element: ReactNode }>;
  hasError?: boolean;
  icon?: ReactNode;
  id?: string;
  dataCy?: string;
}> = ({
  value,
  options,
  label,
  onChange,
  element,
  disabled,
  menuPlacement = 'bottom',
  hasError,
  icon,
  id,
  dataCy,
}) => (
  <Listbox
    //value={value}
    defaultValue={value}
    onChange={onChange}
    disabled={disabled}
    as={'section'}
    className={classNames(
      'relative border-b border-neutral-30',
      'max-w-[100vw] lg:max-w-none',
      hasError
        ? 'border-red-100 text-red-100 focus-within:text-neutral-80 focus-within:border-brand-20'
        : 'border-neutral-30'
    )}
    id={id}
    data-cy={dataCy}
  >
    <Listbox.Button
      className={
        'w-full flex items-end relative min-h-[3rem] py-0.5 pl-4 pr-10 box-border'
      }
    >
      <div
        className={classNames(
          'w-full cursor-pointer bg-white disabled:bg-neutral-30 disabled:opacity-90 text-left focus:outline-none focus:bg-brand-10 focus:border-brand-20 sm:text-sm'
        )}
      >
        <span
          className={classNames(
            'absolute block font-base tracking-normal transition-transform duration-300 origin-top-left',
            !hasError && 'text-brand-100',
            !!value
              ? 'left-4 scale-[0.9] -translate-y-full'
              : 'left-4 top-1/2 scale-100 -translate-y-1/2'
          )}
        >
          {label}
        </span>
        <span
          className={classNames(
            'block tracking-normal transition-transform  text-base delay-300 duration-300',
            !!value ? 'opacity-100' : 'opacity-0'
          )}
        >
          {element}
        </span>
        {icon && (
          <span className="absolute right-8 top-1/2 -translate-y-1/2 w-6 h-6">
            {icon}
          </span>
        )}
      </div>
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <Chevrelon className="w-3" />
      </span>
    </Listbox.Button>
    <Transition
      as={Fragment}
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Listbox.Options
        className={classNames(
          'bg-white z-10 absolute max-h-60 w-full overflow-auto py-1 text-base shadow-sm focus:outline-none sm:text-sm border border-neutral-30',
          menuPlacement === 'top'
            ? '-translate-y-full top-[-1px]'
            : 'bottom-[1px] translate-y-full'
        )}
      >
        {options?.map((option) => (
          <Listbox.Option
            key={option.id}
            className={({ active, selected }) =>
              classNames(
                `relative items-center cursor-default select-none min-h-[3rem] grid text-sm px-4 focus-visible:border-brand-10`,
                selected ? 'bg-brand-20 text-neutral-80' : 'text-neutral-70',
                active ? 'bg-brand-10 text-neutral-80' : 'text-neutral-70'
              )
            }
            value={option.value}
          >
            {option.element}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Transition>
  </Listbox>
);

export default Select;
