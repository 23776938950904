module.exports = async (brandSlug) => {
  if (!!brandSlug) {
    try {
      const brandFetch = await fetch(
        `${process.env.API_HOST_SHOP}/en/shop/cars/by_slug`
      );
      const brands = await brandFetch.json();

      if (!brands?.children || brands.children.length === 0) {
        throw Error('No brands were returned');
      }

      const currentBrand = brands.children.filter((b) => b.slug === brandSlug);

      if (currentBrand.length === 0) {
        throw Error('No brand match for brand slug on env');
      }

      return currentBrand[0].name;
    } catch (e) {
      console.error(e);
    }
  } else {
    return null;
  }
};
