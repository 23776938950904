import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();

export class CustomError {
  status: number;
  data?: unknown;
  message?: string;
  endpoint?: string;
  content?: string;
  useragent?: string;
  constructor(
    args:
      | {
          status: number;
          data: unknown;
          endpoint?: string;
          content?: string[];
          useragent?: string;
        }
      | {
          status: 500;
          message: string;
          endpoint?: string;
          content?: string[];
          useragent?: string;
        }
  ) {
    this.status = args.status;
    if (args.status !== 500) {
      this.data = args.data;
    } else {
      this.message = args.message;
    }
  }
}

export const wrapFetch = async <T>(
  request: Promise<Response>,
  options?: Parameters<typeof fetch>[1]
): Promise<T> => {
  const response = await request;

  if (!response.ok) {
    let requestBody: string | string[] | undefined = undefined;
    let requestBodyStriped: string[] | null = null;

    if (response.status === 500) {
      requestBody = await response.text();
      requestBodyStriped = requestBody.match(
        /<div id="summary">[\S\s]*?(?=<\/div>)/gm
      );
    }

    try {
      const apiError = await response.json();

      const theError = new CustomError({
        status: response.status,
        endpoint: response.url,
        content: requestBodyStriped
          ? (requestBodyStriped as string[])
          : undefined,
        useragent: options?.headers?.get('user-agent'),
        data: apiError,
      });

      if (response.status === 500) {
        const scope = new Sentry.Scope();
        scope.setExtra('Endpoint', response.url);
        scope.setExtra('API error summary', requestBodyStriped);
        Sentry.captureException(theError, scope);
      }

      throw theError;
    } catch (e) {
      if (e instanceof CustomError) {
        throw e;
      }

      const theError = new CustomError({
        status: 500,
        endpoint: response.url,
        content: requestBodyStriped
          ? (requestBodyStriped as string[])
          : undefined,
        useragent: options?.headers?.get('user-agent'),
        message: e.message,
      });

      if (response.status === 500) {
        const scope = new Sentry.Scope();
        scope.setExtra('Endpoint', response.url);
        scope.setExtra('API error summary', requestBodyStriped);
        Sentry.captureException(theError, scope);
      }

      throw theError;
    }
  }
  return response.status !== 204 ? response.json() : true;
};

export const fechFromBPartsApi = <T>(
  target: Parameters<typeof fetch>[0],
  options?: Parameters<typeof fetch>[1],
  internalOptions?: {
    enableCustomErrorWrapper?: boolean;
    enableHostRemoval?: boolean;
  }
): Promise<T> => {
  // api target configuration and remove cors from dev mode
  const newTarget = `${serverRuntimeConfig.apiHost || '/api'}${target}`;

  const { enableCustomErrorWrapper = false, enableHostRemoval = true } =
    internalOptions || {};

  // removing hosts due to next js server host injection
  if (enableHostRemoval && options?.headers) {
    if (
      options.headers.delete &&
      typeof options.headers.delete === 'function'
    ) {
      options.headers.delete('host');
    } else if (options.headers.host) {
      delete options?.headers.host;
    }
  }

  if (enableCustomErrorWrapper) {
    return wrapFetch<T>(fetch(newTarget, options), options);
  }

  return fetch<T>(newTarget, options);
};
