module.exports = {
  locales: process.env.NEXT_PUBLIC_HOST?.includes('.co.uk')
    ? ['en']
    : ['en', 'es', 'pt', 'fr', 'it', 'nb', 'da', 'de', 'nl'],
  defaultLocale: 'en',
  id: 1,
  logo: {
    img: `${process.env.PUBLIC_PREFIX}/svg/logo.svg`,
  },
  font: {
    name: 'Nunito Sans',
    url: 'https://fonts.googleapis.com/css?family=Nunito+Sans:300,700&display=swap',
  },
};
