import { SVGProps } from 'react';

const IconClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M12.1972 1.09569L1.09569 12.1972L1.8028 12.9043L12.9043 1.8028L12.1972 1.09569ZM13.1157 12.4086L1.59135 0.88424L0.88424 1.59135L12.4086 13.1157L13.1157 12.4086Z"
      fill="currentColor"
    />
  </svg>
);

export default IconClose;
