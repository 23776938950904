import Image from 'next/legacy/image';
import { ComponentProps, FunctionComponent, useState } from 'react';

export const myLoader = ({ src }: { src: string }) => {
  return src;
};

const BPImage: FunctionComponent<
  ComponentProps<typeof Image> & { fallbackSrc?: string }
> = ({ fallbackSrc, ...props }) => {
  const [imgSrc, set_imgSrc] = useState(props.src);

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      {...props}
      src={imgSrc}
      unoptimized
      loader={myLoader}
      onLoadingComplete={(result) => {
        if (result.naturalWidth === 0 && fallbackSrc) {
          // Broken image
          set_imgSrc(fallbackSrc);
        }
      }}
      onError={() => {
        fallbackSrc && set_imgSrc(fallbackSrc);
      }}
    />
  );
};

export default BPImage;
