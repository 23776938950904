import { FunctionComponent, HTMLAttributes } from 'react';

const Chevrelon: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = (
  props
) => (
  <svg
    {...props}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 14 8"
    fill="none"
  >
    <path
      d="M13.3536 1.35355C13.5488 1.15829 13.5488 0.841709 13.3536 0.646447C13.1583 0.451184 12.8417 0.451184 12.6464 0.646447L13.3536 1.35355ZM7 7L6.64645 7.35355C6.84171 7.54882 7.15829 7.54882 7.35355 7.35355L7 7ZM1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM12.6464 0.646447L6.64645 6.64645L7.35355 7.35355L13.3536 1.35355L12.6464 0.646447ZM7.35355 6.64645L1.35355 0.646447L0.646447 1.35355L6.64645 7.35355L7.35355 6.64645Z"
      fill="currentColor"
    />
  </svg>
);

export default Chevrelon;
