import { Transition } from '@headlessui/react';
import cx from 'classnames';
import {
  useRef,
  useEffect,
  FunctionComponent,
  ComponentProps,
  ReactNode,
} from 'react';

import useOnClickOutside from '../../../hooks/click-outside';
import Button from '../../ui/button';
import IconClose from '../../ui/icons/close';

const CloseButton: FunctionComponent<
  ComponentProps<'button'> & { closeBorderLeft?: boolean }
> = ({ closeBorderLeft = false, ...props }) => (
  <div
    className={cx(
      'absolute right-0 top-0',
      { 'border-l border-neutral-30 lg:border-none': closeBorderLeft },
      'z-200'
    )}
    data-cy="close-slider"
  >
    <Button {...props}>
      <IconClose className={cx('text-neutral-80', 'w-4')} />
    </Button>
  </div>
);

const leftProps = {
  enterFrom: '-translate-x-full before:opacity-0',
  enterTo: 'translate-x-0 before:opacity-40',
  leaveFrom: 'translate-x-0 before:opacity-40',
  leaveTo: '-translate-x-full before:opacity-0',
};

const rightProps = {
  enterFrom: 'translate-x-full before:opacity-0',
  enterTo: 'translate-x-0 before:opacity-40',
  leaveFrom: 'translate-x-0 before:opacity-40',
  leaveTo: 'translate-x-full before:opacity-0',
};

const Slider: FunctionComponent<{
  isOpen: boolean;
  children: ReactNode;
  enterFrom?: 'left' | 'right';
  isFullscreenMobile?: boolean;
  onClose: () => void;
  closeBorderLeft?: boolean;
}> = ({
  children,
  enterFrom = 'left',
  isFullscreenMobile,
  isOpen,
  onClose,
  closeBorderLeft = false,
}) => {
  const animation = enterFrom === 'left' ? leftProps : rightProps;
  const ref = useRef(null);

  useOnClickOutside(ref, onClose);

  useEffect(() => {
    if (!isOpen) {
      document.body.style.overflow = 'auto';
    }

    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
  }, [isOpen]);

  return (
    <Transition
      unmount
      appear
      show={isOpen}
      {...animation}
      className={cx(
        'fixed bg-white h-full z-200 shadow-md grid transition-transform before:transition-opacity before:duration-500 duration-500',
        "before:w-screen before:content-[''] before:absolute before:h-screen before:bg-neutral-80/40 before:-z-10",
        isFullscreenMobile
          ? 'w-full md:max-w-sm'
          : 'w-[calc(100vw-3rem)] md:max-w-sm',
        enterFrom === 'left'
          ? 'left-0 before:left-full'
          : 'right-0 before:right-full'
      )}
    >
      <CloseButton
        closeBorderLeft={closeBorderLeft}
        tabIndex={isOpen ? 0 : -1}
        onClick={onClose}
      />
      <div
        ref={ref}
        className={cx(
          'relative overflow-hidden bg-white z-10',
          isFullscreenMobile
            ? 'w-full md:max-w-sm'
            : 'w-[calc(100vw-3rem)] md:max-w-sm'
        )}
      >
        {children}
      </div>
    </Transition>
  );
};

export default Slider;
