import cx from 'classnames';
import dynamic from 'next/dynamic';
import { FunctionComponent, ReactElement } from 'react';

import styles from './page-wrapper.module.css';

const AdvancedSearchPicker = dynamic(() => import('../advanced-search-picker'));
const AdvancedSearchFooter = dynamic(
  () => import('../footer/advanced-search-footer')
);

type TPageWrapper = {
  type?: TPageConfig['wrapper'];
  children: ReactElement;
};

const PageWrapper: FunctionComponent<TPageWrapper> = ({
  type,
  children,
  ...props
}) => {
  switch (type) {
    case 'ADVANCED-SEARCH':
      return (
        <div className={cx(styles['advanced-wrapper'])}>
          <div className={cx(styles['search-column'], 'flex', 'sticky')}>
            <AdvancedSearchPicker />
          </div>
          <div className={cx('flex', 'flex-col')}>
            <div className="grow mb-4">{children}</div>
            <AdvancedSearchFooter />
          </div>
        </div>
      );
    case 'DEFAULT':
    default:
      return children;
  }
};

export default PageWrapper;
