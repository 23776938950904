import { createContext, FunctionComponent, useContext } from 'react';

const focusContenxt = createContext(false);

export const useDisableFocus = () => useContext<boolean>(focusContenxt);

const Provider: FunctionComponent<{ value: boolean }> = ({
  value,
  children,
}) => {
  const previousContext = useDisableFocus();

  return (
    <focusContenxt.Provider value={previousContext || value}>
      {children}
    </focusContenxt.Provider>
  );
};

export default Provider;
