import cx from 'classnames';
import { FunctionComponent, useRef } from 'react';

import classes from './loading-bar.module.css';

const LoadingBar: FunctionComponent<{
  isLoading?: boolean;
  isFixed?: boolean;
  isBig?: boolean;
  color?: string;
  isTop?: boolean;
}> = ({
  isLoading = true,
  isFixed = false,
  isBig = false,
  color = 'bg-brand-100',
  isTop = false,
}) => {
  const ref = useRef(null);

  return (
    <div
      className={cx(
        classes.container,
        isFixed ? 'fixed' : 'sticky h-0',
        'top-0',
        'left-0',
        'w-full',
        isTop ? 'z-40' : 'z-10',
        {
          'opacity-0': !isLoading,
        }
      )}
    >
      <div
        ref={ref}
        className={cx(
          classes.bar,
          {
            [classes.move]: isLoading,
          },
          isBig ? 'h-2 lg:h-1' : 'h-1 lg:h-0.5',
          color
        )}
      />
    </div>
  );
};

export default LoadingBar;
