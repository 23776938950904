import getConfig from 'next/config';
import Script from 'next/script';
import React from 'react';

const { publicRuntimeConfig } = getConfig();

const GTM_KEY = publicRuntimeConfig?.gtmKey;

const initializeGTMEvents = () => {
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });
};

const GTMInjector = () => {
  return (
    <Script
      async
      strategy="afterInteractive"
      onLoad={initializeGTMEvents}
      src={`https://www.googletagmanager.com/gtm.js?id=${GTM_KEY}`}
    />
  );
};

export default function GTM() {
  if (GTM_KEY) {
    return <GTMInjector />;
  }

  return null;
}
