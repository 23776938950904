import cx from 'classnames';
import { atom, useAtom } from 'jotai';
import { Router, useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import LoadingBar from '../../ui/loading-bar';

const requireLoading = atom<Set<string>>(new Set<string>());

const addRequireLoading = atom(null, (get, set, id: string) => {
  const currentValue = new Set(get(requireLoading));
  currentValue.add(id);
  set(requireLoading, currentValue);
});

const removeRequireLoading = atom(null, (get, set, id: string) => {
  const currentValue = new Set(get(requireLoading));
  currentValue.delete(id);
  set(requireLoading, currentValue);
});

export const showLoading = atom((get) => get(requireLoading).size > 0);

export const useAddRequireLoading = () => {
  const [, callback] = useAtom(addRequireLoading);

  return callback;
};

export const useRemoveRequireLoading = () => {
  const [, callback] = useAtom(removeRequireLoading);

  return callback;
};

export const LoadingPlaceholder = ({
  id,
  height,
}: {
  id: string;
  height?: string;
}) => {
  const setNextLoading = useAddRequireLoading();
  const removeNextLoading = useRemoveRequireLoading();

  useEffect(() => {
    setNextLoading(id);
    return () => removeNextLoading(id);
  }, [id, setNextLoading, removeNextLoading]);

  if (height) {
    <div style={{ width: '100%', height: height }} />;
  }

  return null;
};

const NextLoading = () => {
  const { locale = 'en' } = useRouter();
  const [show, setNextLoading] = useState(false);

  useEffect(() => {
    const onEnable = () => setNextLoading(true);
    const onDisable = () => setNextLoading(false);
    Router.events.on('routeChangeStart', onEnable);
    Router.events.on('routeChangeComplete', onDisable);
    Router.events.on('routeChangeError', onDisable);

    return () => {
      Router.events.off('routeChangeStart', onEnable);
      Router.events.off('routeChangeComplete', onDisable);
      Router.events.off('routeChangeError', onDisable);
    };
  }, []);

  if (show) {
    return (
      <>
        <LoadingBar isTop isLoading />
        <div
          className={cx(
            'absolute',
            'top-0',
            'right-0',
            '-bottom-96',
            'left-0',
            'bg-neutral-80',
            'opacity-25',
            'z-40'
          )}
        />
      </>
    );
  }

  return null;
};

export default NextLoading;
