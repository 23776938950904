import { originalRoutes } from '../hooks/use-link-props';

type TGroupPageConfig = {
  [key: string]: TPageConfig;
};

type TSetupsByURL = {
  //[routeKey in keyof typeof originalRoutes]: TPageConfig;
  [key: string]: TPageConfig;
};

export const presetupConfigs: TGroupPageConfig = {
  default: {
    header: 'DEFAULT',
    menu: 'DEFAULT',
    wrapper: 'DEFAULT',
    footerCurve: 'PRIMARY',
    footer: 'DEFAULT',
  },
  checkout: {
    header: 'CHECKOUT',
    menu: 'DEFAULT',
    wrapper: 'DEFAULT',
    footerCurve: 'PRIMARY',
    footer: 'CHECKOUT',
  },
  advancedSearch: {
    header: 'ADVANCED-SEARCH',
    menu: 'ADVANCED-SEARCH',
    wrapper: 'ADVANCED-SEARCH',
    footerCurve: 'NONE',
    footer: 'NONE',
  },
  blank: {
    header: 'NONE',
    menu: 'NONE',
    wrapper: 'DEFAULT',
    footerCurve: 'NONE',
    footer: 'NONE',
  },
};

export const configPage: TSetupsByURL = {
  [originalRoutes['about-us']]: presetupConfigs.default,
  [originalRoutes['advanced-search']]: presetupConfigs.advancedSearch,
  [originalRoutes['advanced-search-products-search']]:
    presetupConfigs.advancedSearch,
  [originalRoutes['advanced-search-product']]: presetupConfigs.advancedSearch,
  [originalRoutes['brands']]: {
    ...presetupConfigs.default,
  },
  [originalRoutes['brands-with-part']]: {
    ...presetupConfigs.default,
  },
  [originalRoutes['categories']]: {
    ...presetupConfigs.default,
  },
  [originalRoutes['categories-with-vehicle']]: {
    ...presetupConfigs.default,
  },
  [originalRoutes['checkout']]: presetupConfigs.checkout,
  [originalRoutes['checkout-login']]: presetupConfigs.checkout,
  [originalRoutes['checkout-payment']]: presetupConfigs.checkout,
  [originalRoutes['checkout-payment-processing']]: presetupConfigs.checkout,
  [originalRoutes['checkout-shipping']]: presetupConfigs.checkout,
  [originalRoutes['checkout-thanks']]: presetupConfigs.checkout,
  [originalRoutes['contacts']]: presetupConfigs.default,
  [originalRoutes['create-part-alert']]: presetupConfigs.default,
  [originalRoutes['faqs']]: presetupConfigs.default,
  [originalRoutes['faqs_group']]: presetupConfigs.default,
  [originalRoutes['index']]: {
    ...presetupConfigs.default,
    footerCurve: 'SECONDARY',
  },
  [originalRoutes['legal-mentions']]: presetupConfigs.default,
  [originalRoutes['login']]: presetupConfigs.default,
  [originalRoutes['models']]: {
    ...presetupConfigs.default,
  },
  [originalRoutes['models-with-part']]: {
    ...presetupConfigs.default,
  },
  [originalRoutes['nps']]: presetupConfigs.default,
  [originalRoutes['password-reset']]: presetupConfigs.default,
  [originalRoutes['password-reset-confirm']]: presetupConfigs.default,
  [originalRoutes['private-area']]: presetupConfigs.default,
  [originalRoutes['private-area-account-details']]: presetupConfigs.default,
  [originalRoutes['private-area-my-cars']]: presetupConfigs.default,
  [originalRoutes['private-area-part-alerts']]: presetupConfigs.default,
  [originalRoutes['private-area-part-alerts-create']]: presetupConfigs.default,
  [originalRoutes['private-area-purchases']]: presetupConfigs.default,
  [originalRoutes['private-area-purchases-order']]: presetupConfigs.default,
  [originalRoutes['product']]: {
    ...presetupConfigs.default,
    footerCurve: 'TRANSPARENT',
  },
  [originalRoutes['products']]: {
    ...presetupConfigs.default,
    footerCurve: 'TRANSPARENT',
  },
  [originalRoutes['reference']]: presetupConfigs.default,
  [originalRoutes['signup']]: presetupConfigs.default,
  [originalRoutes['sub-categories']]: {
    ...presetupConfigs.default,
  },
  [originalRoutes['sub-categories-with-vehicle']]: {
    ...presetupConfigs.default,
  },
  [originalRoutes['terms-and-conditions']]: presetupConfigs.default,
  [originalRoutes['unsubscribe-emails']]: presetupConfigs.default,
  [originalRoutes['vehicle']]: presetupConfigs.default,
  [originalRoutes['versions']]: {
    ...presetupConfigs.default,
  },
  [originalRoutes['versions-with-part']]: {
    ...presetupConfigs.default,
  },
  [originalRoutes['pro']]: presetupConfigs.blank,
};
