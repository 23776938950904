const messagesDA = require('../locales/da/routes.json');
const messagesDE = require('../locales/de/routes.json');
const messagesEN = require('../locales/en/routes.json');
const messagesES = require('../locales/es/routes.json');
const messagesFR = require('../locales/fr/routes.json');
const messagesIT = require('../locales/it/routes.json');
const messagesNB = require('../locales/nb/routes.json');
const messagesNL = require('../locales/nl/routes.json');
const messagesPT = require('../locales/pt/routes.json');

const messages = {
  da: messagesDA,
  de: messagesDE,
  es: messagesES,
  fr: messagesFR,
  it: messagesIT,
  nb: messagesNB,
  pt: messagesPT,
  nl: messagesNL,
};

const permanentRedirects = [
  {
    source: '/de/rechtlich-und-institutionell',
    destination: messages['de']['legal-mentions'],
    locale: false,
    permanent: true,
  },
];

const tempRedirects = [];

module.exports = async () => {
  return [...permanentRedirects, ...tempRedirects];
};
