import { ParsedUrlQuery } from 'querystring';

import { resolveHref } from 'next/dist/client/resolve-href';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';

import { useAppContext } from '../../components/providers/app-context';

// THIS SAVE THE FILESYSTEM ROUTING
export const originalRoutes = {
  contacts: '/contacts',
  'legal-mentions': '/legal-mentions',
  'terms-and-conditions': '/terms-and-conditions',
  'about-us': '/about-us',
  nps: '/nps',
  'unsubscribe-emails': '/unsubscribe-emails/[token]',
  categories: '/auto-parts/categories',
  'categories-with-vehicle': '/auto-parts/[category]/categories', // the category param is, in fact, a vehicle
  'sub-categories': '/auto-parts/[category]',
  'sub-categories-with-vehicle': '/auto-parts/[category]/category/[slug]', // the category param is, in fact, a vehicle
  brands: '/auto-parts/brands',
  'brands-with-part': '/auto-parts/[category]/[subCategory]/brands',
  models: '/auto-parts/models/[brand]',
  'models-with-part': '/auto-parts/[category]/[subCategory]/models/[brand]',
  versions: '/auto-parts/versions/[model]',
  'versions-with-part': '/auto-parts/[category]/[subCategory]/versions/[model]',
  vehicle: '/auto-parts/vehicle/[vehicle]',
  index: '/',
  faqs: '/faqs',
  faqs_group: '/faqs/[group]',
  login: '/login',
  signup: '/signup',
  'password-reset': '/password-reset',
  'password-reset-confirm': '/password-reset-confirm/[token]',
  products: '/auto-parts/[category]/[subCategory]/[vehicle]',
  reference: '/auto-parts/search',
  product: '/auto-parts/[category]/[subCategory]',
  'create-part-alert': '/create-part-alert',
  'private-area': '/private-area',
  'private-area-purchases': '/private-area',
  'private-area-purchases-order': '/private-area',
  'private-area-my-cars': '/private-area',
  'private-area-part-alerts': '/private-area',
  'private-area-part-alerts-create': '/private-area',
  'private-area-account-details': '/private-area',
  checkout: '/checkout',
  'checkout-login': '/checkout',
  'checkout-shipping': '/checkout',
  'checkout-payment': '/checkout',
  'checkout-thanks': '/checkout',
  'checkout-payment-processing': '/checkout',
  'advanced-search': '/advanced-search',
  'advanced-search-products-search': '/advanced-search/search',
  'advanced-search-product': '/advanced-search/[prodCategory]/[prodSlug]',
  pro: '/pro',
};

const useLinkProps = (): ((
  id: keyof typeof originalRoutes,
  query?: ParsedUrlQuery | { [key: string]: string },
  hash?: string
) => { href: LinkProps['href']; as: LinkProps['as'] }) => {
  const { t } = useTranslation();
  const {
    features: { isBrandEnabled },
    brand,
  } = useAppContext();
  const router = useRouter();

  return useCallback(
    (
      id: keyof typeof originalRoutes,
      query?: { [key: string]: string },
      hash?: string
    ) => {
      let currentId = id;
      let currentQuery: { [key: string]: string } & { brand?: string } = {
        ...query,
      };

      if (isBrandEnabled) {
        switch (id) {
          case 'brands':
            currentId = 'models';
            currentQuery.brand = brand;
            break;
          case 'brands-with-part':
            currentId = 'models-with-part';
            currentQuery.brand = brand;
            break;
        }
      }

      return {
        href: {
          pathname: originalRoutes[currentId],
          query: currentQuery,
          hash,
        },
        as: resolveHref(
          router,
          {
            pathname: t(`routes:${currentId}`),
            query: currentQuery,
            hash,
          },
          true
        )[1],
      };
    },
    [isBrandEnabled, router, t, brand]
  );
};

export const useResolveLink = () => {
  const router = useRouter();
  const { t } = useTranslation();
  return useCallback(
    (id: keyof typeof originalRoutes, query = '') => {
      return resolveHref(
        router,
        {
          pathname: t(`routes:${id}`),
          query: query,
        },
        true
      )[1];
    },
    [router, t]
  );
};

export default useLinkProps;
