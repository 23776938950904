import cx from 'classnames';
import { ComponentProps, forwardRef } from 'react';

import classes from './featured.module.css';

const variants: { [key: string]: string } = {
  default: cx('font-bold', 'text-brand-100'),
  blue: cx('text-bparts-100 text-lg font-bold'),
  fullBlue: cx('text-neutral-80 hover:text-bparts-100'),
  neutral: '',
};

const FeaturedLink = forwardRef<
  HTMLAnchorElement,
  {
    as?: 'a' | 'button';
    variant?:
      | 'default'
      | 'neutral'
      | 'black'
      | 'white'
      | 'blue'
      | 'blank'
      | 'fullBlue';
  } & ComponentProps<'a'>
>(
  (
    {
      as: Component = 'a',
      variant = 'default',
      className,
      disableFocusOnMenu,
      ...props
    },
    ref
  ) => (
    <Component
      ref={ref}
      className={cx(
        [classes.featured],
        { [classes.defaultLine]: variant === 'default' },
        { [classes.transparentLine]: variant === 'neutral' },
        { [classes.blackLine]: variant === 'black' },
        { [classes.whiteLine]: variant === 'white' },
        { [classes.blueLine]: variant === 'blue' },
        { [classes.fullBlue]: variant === 'fullBlue' },
        variants[variant],
        'cursor-pointer',
        { 'pb-1': variant !== 'blank' },
        'relative',
        variant === 'fullBlue'
          ? 'py-1 mx-0 px-1 focus:outline-none focus:bg-white'
          : 'focus:outline-none focus:bg-brand-10',
        className
      )}
      {...props}
    />
  )
);

FeaturedLink.displayName = 'FeaturedLink';

export default FeaturedLink;
