import { atom, useAtom } from 'jotai';

const key = atom<number>(0);

export const useErrorKey = () => useAtom(key);

export const setResetKey = atom(null, (get, set) => {
  set(key, (a) => a + 1);
});

export const useSetResetKey = () => useAtom(setResetKey)[1];
