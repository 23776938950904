import { atom } from 'jotai';
import { useSetAtom } from 'jotai';
import Cookies from 'js-cookie';

import {
  CONCENT_MARKTING,
  CONCENT_PREFERENCES,
  CONCENT_STATISTICS,
  CONCENT_REQUIRED,
} from '../static/cookies';

// this atom define if concent was already granted
export const requiredAtom = atom<boolean | null>(false);

export const preferencesAtom = atom<boolean | null>(false);
export const marktingAtom = atom<boolean | null>(false);
export const statisticsAtom = atom<boolean | null>(false);
export const openCookieConcent = atom<boolean | null>(false);

export const useUpdateConcentAtom = () => {
  const setRequiredAtom = useSetAtom(requiredAtom);
  const setPreferencesAtom = useSetAtom(preferencesAtom);
  const setMarktingAtom = useSetAtom(marktingAtom);
  const setStatisticsAtom = useSetAtom(statisticsAtom);

  return () => {
    setPreferencesAtom(
      () => !!parseInt(Cookies.get(CONCENT_PREFERENCES) || '0', 10)
    );
    setMarktingAtom(() => !!parseInt(Cookies.get(CONCENT_MARKTING) || '0', 10));
    setStatisticsAtom(
      () => !!parseInt(Cookies.get(CONCENT_STATISTICS) || '0', 10)
    );
    setRequiredAtom(() => !!parseInt(Cookies.get(CONCENT_REQUIRED) || '0', 10));
  };
};
