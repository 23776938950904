import { IncomingMessage } from 'http';

import { fechFromBPartsApi } from '../utils';

export const fetchShippingLocations = (
  {
    locale,
  }: {
    locale: string;
  },
  req?: IncomingMessage
): Promise<ShippingLocations> =>
  fechFromBPartsApi(`/${locale}/shop/shipping_locations`, {
    credentials: 'include',
    headers: req ? new Headers(req.headers as HeadersInit) : undefined,
  }).then((value) => value.json());

// TODO: add correct TS
export const fetchUserShippingLocation = ({
  locale,
}: {
  locale: string;
}): Promise<{ id: number }> =>
  fechFromBPartsApi(`/${locale}/shop/shipping_locations/selected`, {
    credentials: 'include',
  }).then((value) => value.json());
