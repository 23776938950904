import { atom } from 'jotai';

export const cartOpen = atom<boolean>(false);

export const openCartToggle = atom(null, (get, set, by) =>
  set(cartOpen, (value) => !value)
);

export const settingsModalOpen = atom<boolean>(false);

export const settingsModalToggle = atom(null, (get, set, by) =>
  set(settingsModalOpen, (value) => !value)
);

export const addedProduct = atom<number | null>(0);
export const removedProduct = atom<number | null>(0);

export const cartAtom = atom<Array<number>>([]);
export const addToCartAtom = atom(null, (get, set, by) => {
  let cart = get(cartAtom);
  cart.push(by);
  set(cartAtom, cart);
});
export const removeFromCartAtom = atom(null, (get, set, by) => {
  let cart = get(cartAtom);
  cart = cart.filter((f) => f !== by);
  set(cartAtom, cart);
});
