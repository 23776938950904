const getBrandName = require('./get-brand-name');
const i18n = require('./i18n');
const getRedirects = require('./redirects');
const getRoutes = require('./routes');

module.exports = {
  i18n,
  getRoutes,
  getRedirects,
  getBrandName,
  localeDetection: false,
};
